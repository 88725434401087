import React from "react";
import "../Styles/ExtendedService.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import img from "../Images/GD.png";
import img21 from '../Images/a3.png'
import img31 from '../Images/a1.png'
import img41 from '../Images/a2.png'
import img51 from '../Images/a4.png'
import {
    Fa500Px,
  FaAssistiveListeningSystems,
  FaBuffer,
  FaBuilding,
  FaChromecast,
  FaClone,
  FaTrello,
  FaUsb,
  FaUserInjured,
  FaYelp,
} from "react-icons/fa";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import img1 from "../Images/ex-services.png";
// ..
AOS.init();
const GraphicDesgin = () => {
  return (
    <>
      <ParallaxProvider>
        <Parallax speed={0}>
          <div className="mainExtended">
            {/* <Parallax speed={0}> */}
            <div className="extended_header">
              <div className="extended_main_header">
                <div className="hero_circle"></div>
                <span className="extended_HeadText">APP DEVELOPMENT</span>
                <div className="extended_text">
                Our expertise lies in creating captivating and innovative app solutions. We harness the latest technologies to engage users effectively while tailoring our development to meet the unique requirements of each customer, ensuring exceptional outcomes.
                </div>
              </div>
              <div className="extended_img">
                <img src={img31} alt="" />
              </div>
              {/* <div className="hero_c"></div> */}
            </div>
            {/* </Parallax> */}
            <div className="extended_cards_main">
              <div data-aos="zoom-in" className="extended_cards "style={{height:"450px"}} >
              <div className="extended_icon">
                  <img className="extended_icon_image" src={img41} alt="" />
                </div>
                <div className="extended_icon_header"> Cross-Platform Expertise</div>
                <div className="extended_icon_text">
                Highlight your expertise in cross-platform app development using Flutter. Explain how Flutter allows you to create apps that work seamlessly on multiple platforms, reducing development costs and time-to-market for your clients.
                </div>
              </div>
              <div data-aos="zoom-in" className="extended_cards" style={{height:"450px"}} >
              <div className="extended_icon">
                  <img className="extended_icon_image" src={img21} alt="" />
                </div>
                <div className="extended_icon_header">
                Customized Solutions
                </div>
                <div className="extended_icon_text">
                Emphasize your ability to tailor app solutions to your clients' specific needs and requirements. Discuss how you work closely with clients to understand their goals and deliver custom Flutter app solutions that align with their business objectives.
                </div>
              </div>
              <div data-aos="zoom-in" className="extended_cards"style={{height:"450px"}}>
              <div className="extended_icon">
                  <img className="extended_icon_image" src={img51} alt="" />
                </div>
                <div className="extended_icon_header"> Comprehensive Services </div>
                <div className="extended_icon_text">
                Outline the full spectrum of app development services you offer in addition to Flutter development. This may include UI/UX design, backend development, quality assurance, maintenance, and support.
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </ParallaxProvider>
    </>
  );
};

export default GraphicDesgin;
