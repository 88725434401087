import React, { useEffect } from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { Route, BrowserRouter, Routes, useLocation } from "react-router-dom";
import "./app.css";
import ContactsUs from "./Pages/ContactsUs";
import ExtendedAboutus from "./Pages/ExtendedAboutus";
import ExtendedService from "./Pages/ExtendedService";
import GraphicDesgin from "./Pages/GraphicDesgin";
import AppDevelopment from "./Pages/AppDevelopment";
import IOT from "./Pages/IOTdevelopment";
import ProductManagement from "./Pages/ProductManagement";
import SEO from "./Pages/SEO";
import Home from "./Pages/Home/Home";
import PreLoader from "./Components/preLoader";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // This component doesn't render anything
}

function App() {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {isLoading ? (
          <PreLoader />
        ) : (
          <>
            <Navbar />
            <ScrollToTop /> {/* Add the ScrollToTop component */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/extendedAboutUs" element={<ExtendedAboutus />} />
              <Route path="/contactUs" element={<ContactsUs />} />
              <Route path="/extendedservices" element={<ExtendedService />} />
              <Route path="/seo" element={<SEO />} />
              <Route path="/graphicDesign" element={<GraphicDesgin />} />
              <Route path="/appDevelopment" element={<AppDevelopment />} />
              <Route
                path="/productmanagement"
                element={<ProductManagement />}
              />
              <Route path="/iot" element={<IOT />} />
            </Routes>
            <Footer />
          </>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
