import React from 'react';
import './preloader.css';

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="preloader-text">VERVE<span className='preolader-specialtext'>X.</span></div>
    </div>
  );
};

export default Preloader;
