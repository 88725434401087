import React, { useRef, useState } from "react";
import "../../Styles/Testemonial.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

const Testemonial = () => {
  return (
    <>
      <div className="Testemonial_MainContaier">
        <div className="TEST_circle"></div>
        <div className="Testemonial_headText">TESTEMONIALS</div>
        <div className="Testemonial_SubContaier">
          <Swiper
            slidesPerView={3}
            spaceBetween={50}
            pagination={{
              clickable: true,
            }}
            loop="true"
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide className="test_slide">
              <div className="test_slide_imageDiv">
                <img
                  src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1696239910~exp=1696240510~hmac=d25f96d748517063651294044453b1b48fe253d4e3bdede21779b4d7f7ce93b4"
                  alt=""
                  className="test_image_slide"
                />
                <div className="text_slide_image_text">
                Vervex has been an incredible partner for VisaForSiksha.com. Their website building services have transformed our online presence. Our site now not only looks fantastic but also functions seamlessly. 
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="test_slide">
              <div className="test_slide_imageDiv">
              {/* <div className="test_slide_imageContanier"> */}

                <img
                  src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1696239910~exp=1696240510~hmac=d25f96d748517063651294044453b1b48fe253d4e3bdede21779b4d7f7ce93b4"
                  alt=""
                  className="test_image_slide"
                  />
                  {/* </div> */}
                <div className="text_slide_image_text">
                Working with Vervex was a game-changer for IronGym. Their team understood our unique needs and delivered a website that perfectly represents our brand. 
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="test_slide">
              <div className="test_slide_imageDiv">
                <img
                  src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1696239910~exp=1696240510~hmac=d25f96d748517063651294044453b1b48fe253d4e3bdede21779b4d7f7ce93b4"
                  alt=""
                  className="test_image_slide"
                />
                <div className="text_slide_image_text">
                We approached Vervex to revamp our outdated website at Bric International. From the initial consultation to the final launch, Vervex's team displayed unparalleled professionalism and technical prowess.
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide className="test_slide">
              <div className="test_slide_imageDiv">
                <img
                  src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1696239910~exp=1696240510~hmac=d25f96d748517063651294044453b1b48fe253d4e3bdede21779b4d7f7ce93b4"
                  alt=""
                  className="test_image_slide"
                />
                <div className="text_slide_image_text">
                GreenHarvest Organics had the pleasure of collaborating with Vervex for our website redesign, and the results were nothing short of exceptional.
                </div>
              </div>
            </SwiperSlide> */}
           
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Testemonial;
