import React, { useState } from "react";
import "../Styles/ContactUs.css";
import img1 from "../Images/CU1.png";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactsUs = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail2 = (e) => {
    e.preventDefault();

    const emailData = {
      from_name: username,
      to_name: email,
      message: message,
      from_phone: phone,
      from_company: company,
    };

    emailjs
      .send(
        "service_741cq8t",
        "template_0jidflr",
        emailData,
        "AHjaMFRhLparjIYFP"
      )
      .then((result) => {
        toast.success("Request Sent!"); // Show success toast
        console.log(result.text);
      })
      .catch((error) => {
        toast.error("Unable to send request"); // Show error toast
        console.log(error.text);
      });
  };

  return (
    <>
      <div className="CU_mainContainer">
        <div className="CU_gridContainer">
          <div className="cu_gridDiv1_1">
            <div className="CU_Circle1"></div>
            <div className="CU_gridDiv1_text">
              Let’s Develop a <br />
              <span className="CU_SpecialText">Customized </span>
              product for you
            </div>
          </div>
          <div className="CU_gridDiv1_2">
            <div className="CU_gridDIv2_imageDiv">
              <img src={img1} alt="" className="CU_gridDIv2_image" srcSet="" />
            </div>
          </div>
        </div>
        <div className="CU_gridContainer2">
          <form onSubmit={sendEmail2}>
            <div className="cu_gridDiv2_1">
              <div className="CU_InputContainer">
                <div className="CU_InputDiv">
                  <input
                    onChange={(e) => setUsername(e.target.value)}
                    type="text"
                    placeholder="NAME"
                    className="CU_input input2"
                    value={username}
                    name="from_name"
                  />
                </div>
                <div className="CU_InputDiv">
                  <input
                    type="text"
                    placeholder="TITTLE/ROLE"
                    className="CU_input input2"
                  />
                </div>
              </div>
              <div className="CU_InputContainer">
                <div className="CU_InputDiv">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    required="true"
                    type="text"
                    placeholder="EMAIL"
                    className="CU_input input2"
                    value={email}
                    name="to_name"
                  />
                </div>
                <div className="CU_InputDiv">
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    placeholder="PHONE"
                    className="CU_input input2"
                    value={phone}
                    name="from_phone"
                  />
                </div>
              </div>
              <div className="CU_inputMainContainer">
                <div className="CU_InputContainer2">
                  <div className="CU_InputDiv">
                    <input
                      onChange={(e) => setCompany(e.target.value)}
                      style={{ width: "100%" }}
                      type="text"
                      placeholder="COMPANY"
                      className="CU_input"
                      value={company}
                      name="from_company"
                    />
                  </div>
                </div>
                <div className="CU_InputContainer2">
                  <div className="CU_InputDiv">
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      placeholder="WHAT WE CAN HELP YOU WITH?"
                      className="CU_input"
                    />
                  </div>
                </div>
                <div className="CU_InputContainer2">
                  <div className="CU_InputDiv">
                    <input
                      onChange={(e) => setMessage(e.target.value)}
                      style={{ width: "100%" }}
                      type="text"
                      placeholder="PLEASE TELL US MORE... (E.G.: YOUR IDEA, TIMELINE, BUDGET.)"
                      className="CU_input"
                      value={message}
                      name="message"
                    />
                  </div>
                </div>
              </div>
              <div className="CU_buttonContainer">
                <input
                  type="submit"
                  className="CU_buttonContainer2"
                  value="Submit "
                />
              </div>
            </div>
          </form>
          <div className="cu_gridDiv2_2">
            <div class="CU_Circle2"></div>
            <div className="CU_CircleTextdiv">
              <div className="CU_CircleText">
                Tell us about your <br /> project
              </div>
              <div className="CU_CircleText2">
                Schedule a free consultation at vervex.technology@gmail.com, or
                fill out the form and we will follow up with you shortly.
                <br className="br" />
                <br className="br" />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        style={{
          // backgroundColor: 'white',
          color: "black",
        }}
      />{" "}
      {/* Add this line to render the toast container */}
    </>
  );
};

export default ContactsUs;
