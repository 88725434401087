import React from "react";
import "../Styles/ExtendedService.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import img from "../Images/GD.png";
import img21 from '../Images/p3.png'
import img31 from '../Images/p1.png'
import img41 from '../Images/p2.png'
import img51 from '../Images/p4.png'
import {
    Fa500Px,
  FaAssistiveListeningSystems,
  FaBuffer,
  FaBuilding,
  FaChromecast,
  FaClone,
  FaTrello,
  FaUsb,
  FaUserInjured,
  FaYelp,
} from "react-icons/fa";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import img1 from "../Images/ex-services.png";
// ..
AOS.init();
const GraphicDesgin = () => {
  return (
    <>
      <ParallaxProvider>
        <Parallax speed={0}>
          <div className="mainExtended">
            {/* <Parallax speed={0}> */}
            <div className="extended_header">
              <div className="extended_main_header">
                <div className="hero_circle"></div>
                <span className="extended_HeadText">PRODUCT MANAGEMENT</span>
                <div className="extended_text">
                Product management involves overseeing the development, marketing, and success of a product or service throughout its lifecycle. This role requires a deep understanding of customer needs, market dynamics, and the organization's goals. Product managers are responsible for making strategic decisions and executing plans to ensure the product or service meets its objectives.
                </div>
              </div>
              <div className="extended_img">
                <img src={img31} alt="" />
              </div>
              {/* <div className="hero_c"></div> */}
            </div>
            {/* </Parallax> */}
            <div className="extended_cards_main">
              <div data-aos="zoom-in" className="extended_cards " >
              <div className="extended_icon">
                  <img className="extended_icon_image" src={img41} alt="" />
                </div>
                <div className="extended_icon_header"> Market Research:</div>
                <div className="extended_icon_text">
                Understand market trends, customer needs, and competition related to the service you plan to offer.
                </div>
              </div>
              <div data-aos="zoom-in" className="extended_cards"  >
              <div className="extended_icon">
                  <img className="extended_icon_image" src={img21} alt="" />
                </div>
                <div className="extended_icon_header">
                Performance Monitoring: 
                </div>
                <div className="extended_icon_text">
                Track key metrics related to service usage, customer satisfaction, and revenue generation.
                </div>
              </div>
              <div data-aos="zoom-in" className="extended_cards">
              <div className="extended_icon">
                  <img className="extended_icon_image" src={img51} alt="" />
                </div>
                <div className="extended_icon_header">Service Evolution</div>
                <div className="extended_icon_text">
                Continuously update and adapt the service to meet changing customer needs and market conditions.
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </ParallaxProvider>
    </>
  );
};

export default GraphicDesgin;
