import React from "react";
import "../Styles/ExtendedService.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import img from "../Images/GD.png";
import imgi2 from '../Images/iot (1).png'
import imgi1 from '../Images/iot (4).png'
import imgi3 from '../Images/iot (3).png'
import imgi4 from '../Images/iot (2).png'
import {
    Fa500Px,
  FaAssistiveListeningSystems,
  FaBuffer,
  FaBuilding,
  FaChromecast,
  FaClone,
  FaTrello,
  FaUsb,
  FaUserInjured,
  FaYelp,
} from "react-icons/fa";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import img1 from "../Images/ex-services.png";
// ..
AOS.init();
const GraphicDesgin = () => {
  return (
    <>
      <ParallaxProvider>
        <Parallax speed={0}>
          <div className="mainExtended">
            {/* <Parallax speed={0}> */}
            <div className="extended_header">
              <div className="extended_main_header">
                <div className="hero_circle"></div>
                <span className="extended_HeadText">IOT DEVELOPMENT</span>
                <div className="extended_text">
                We also provide support for IoT (Internet of Things) development, enabling us to offer comprehensive solutions that integrate smart devices and sensors to enhance connectivity and automation for our clients
                </div>
              </div>
              <div className="extended_img">
                <img src={imgi1} alt="" />
              </div>
              {/* <div className="hero_c"></div> */}
            </div>
            {/* </Parallax> */}
            <div className="extended_cards_main">
              <div data-aos="zoom-in" className="extended_cards " >
              <div className="extended_icon">
                  <img className="extended_icon_image" src={imgi2} alt="" />
                </div>
                <div className="extended_icon_header"> Connected Ecosystem</div>
                <div className="extended_icon_text">
                IoT services create interconnected ecosystems of devices and sensors that can collect, transmit, and analyze data in real-time.
                </div>
              </div>
              <div data-aos="zoom-in" className="extended_cards"  >
              <div className="extended_icon">
                  <img className="extended_icon_image" src={imgi3} alt="" />
                </div>
                <div className="extended_icon_header">
                Custom Solutions
                </div>
                <div className="extended_icon_text">
                IoT services are tailored to the specific needs of industries such as healthcare, manufacturing, agriculture, and smart cities.
                </div>
              </div>
              <div data-aos="zoom-in" className="extended_cards">
              <div className="extended_icon">
                  <img className="extended_icon_image" src={imgi4} alt="" />
                </div>
                <div className="extended_icon_header"> Security and Scalability </div>
                <div className="extended_icon_text">
                IoT services prioritize data security and privacy, implementing robust encryption, authentication, and access control measures to protect sensitive information.
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </ParallaxProvider>
    </>
  );
};

export default GraphicDesgin;
